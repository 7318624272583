<template>
  <div class="ces-main" style="height: 100%">
    <div class="boxbutton">
      <el-row>
        <el-col :span="16">
          <div class="grid-content bg-purple">
            <!--<el-button type="primary" plain size="mini" v-if="searchFlag" @click="planwaybillSearch">查询</el-button>-->
            <el-button type="primary" plain size="mini" v-if="planModifyFlag" @click="planwaybillModify">修改</el-button>
            <el-button type="primary" plain size="mini" v-if="mergeFlag" @click="planwaybillmerge">合单</el-button>
            <el-button type="primary" plain size="mini" v-if="cancelFlag" @click="planwaybillcancel">撤单</el-button>
            <el-tooltip class="item" effect="dark" content="提示：请先选择查询条件再进行导出." placement="bottom">
              <el-button type="primary" plain size="mini" v-if="exportFlag" @click="plannedWaybillExport">导出</el-button>
            </el-tooltip>
            <el-button type="primary" plain size="mini" v-if="searchOne" @click="handleClose" :loading="loading">{{
              loading ? '查询中 ...' : '高级查询刷新'
            }}</el-button>
            <el-button type="primary" plain size="mini" v-if="mergeFlag" @click="combineManualCheck">人工合单</el-button>
            <!-- <el-button type="primary" plain size="mini" @click="handleClose" >查 询</el-button> -->
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <el-input
              maxlength="50"
              placeholder="请输入外部单据号"
              v-model="AccurateAndUnique_preOrderNo"
              size="mini"
              style="width: 250px"
              class="input-with-input"
            >
              <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
            </el-input>
            <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="handleSearch">高级查询</el-button>
          </div>
        </el-col>
        <el-drawer
          title="计划运单高级查询条件"
          :before-close="cancelForm"
          :visible.sync="dialog"
          :direction="ttb"
          custom-class="demo-drawer"
          ref="drawer"
        >
          <div class="demo-drawer__content">
            <el-row>
              <el-col>
                <div class="grid-content bg-purple">
                  <el-card class="box-card">
                    <div>
                      <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                        <el-form-item label="收货方名称" prop="vendeeName">
                          <el-input maxlength="18" v-model="dataForms.vendeeName" placeholder="支持模糊搜索" style="width: 220px"></el-input>
                        </el-form-item>
                        <el-form-item label="发货方" prop="consignor">
                          <el-input maxlength="18" v-model="dataForms.consignor" placeholder="支持模糊搜索" style="width: 220px"></el-input>
                        </el-form-item>
                        <el-form-item label="收货人" prop="vendeeLinkman">
                          <el-input maxlength="18" v-model="dataForms.vendeeLinkman" placeholder="支持模糊搜索" style="width: 220px"></el-input>
                        </el-form-item>
                        <el-form-item label="运单号" prop="orderNo">
                          <el-input v-model="dataForms.orderNo" style="width: 220px"></el-input>
                        </el-form-item>
                        <el-form-item label="状态" prop="status">
                          <el-select v-model="dataForms.status" placeholder="请选择订单状态" style="width: 220px">
                            <el-option label="请选择" value=""></el-option>
                            <el-option label="初始" value="0"></el-option>
                            <el-option label="已确认" value="2"></el-option>
                            <el-option label="已撤单" value="4"></el-option>
                          </el-select>
                        </el-form-item>
                        <el-form-item label="创建开始时间" prop="createTimeBegin">
                          <el-date-picker
                            v-model="dataForms.createTimeBegin"
                            type="date"
                            placeholder="选择日期时间"
                            align="right"
                            :picker-options="pickerOptions"
                          >
                          </el-date-picker>
                        </el-form-item>
                        <el-form-item label="创建结束时间" prop="createTimeEnd">
                          <el-date-picker
                            v-model="dataForms.createTimeEnd"
                            type="date"
                            placeholder="选择日期时间"
                            align="right"
                            :picker-options="pickerOptions"
                          >
                          </el-date-picker>
                        </el-form-item>
                        <!--<el-form-item label="客户要求到货开始时间" prop="homeUrl">-->
                        <!--<el-date-picker-->
                        <!--v-model="dataForms.requiredDeliveryTimeBegin"-->
                        <!--type="datetime"-->
                        <!--placeholder="选择日期时间"-->
                        <!--align="right"-->
                        <!--:picker-options="pickerOptions">-->
                        <!--</el-date-picker>-->
                        <!--</el-form-item>-->
                        <!--<el-form-item label="客户要求到货截止时间" prop="homeUrl">-->
                        <!--<el-date-picker-->
                        <!--v-model="dataForms.requiredDeliveryTimeEnd"-->
                        <!--type="datetime"-->
                        <!--placeholder="选择日期时间"-->
                        <!--align="right"-->
                        <!--:picker-options="pickerOptions">-->
                        <!--</el-date-picker>-->
                        <!--</el-form-item>-->
                      </el-form>
                      <div slot="footer" class="dialog-footer" style="text-align: center">
                        <el-button size="mini" v-if="searchFlag" type="primary" @click="handleClose" :loading="loading">{{
                          loading ? '提交中 ...' : '查 询'
                        }}</el-button>
                        <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                        <el-button size="mini" type="danger" @click="resetFrom">重置</el-button>
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-drawer>
      </el-row>
    </div>
    <div class="tableMain">
      <ces-table
        size="mini"
        :isSelection="true"
        :isIndex="true"
        :isPagination="true"
        :isHandle="true"
        v-loading="loading"
        :tableData="tableData"
        :tableCols="tableCols"
        :pagination="pagination"
        @refresh="handleCurrentChange"
        @handleProNoderClick="handleProNoderClick"
        ref="cesTable"
      >
      </ces-table>
    </div>

    <el-dialog
      title="计划运单省市区信息修改"
      :visible.sync="dialogVisible_planwaybill"
      width="65%"
      :modal="true"
      center
      :before-close="handleClose_planwaybill"
    >
      <el-form :model="waybillDoForm" ref="waybillDoForm" label-width="110px" class="demo-ruleForm" size="mini" label-position="center">
        <el-card class="box-card" :body-style="{ padding: '10px' }">
          <div slot="header">
            <span style="font-size: 14px">发货方省市区信息</span>
          </div>
          <div>
            <el-row>
              <el-col :span="24">
                <SellerListCityLinkage @SellerList="getSellerListCityLinkageCity" :SellerList="SellerList"></SellerListCityLinkage>
              </el-col>
            </el-row>
          </div>
        </el-card>
        <el-card class="box-card" :body-style="{ padding: '10px' }" style="margin-top: 10px">
          <div slot="header">
            <span style="font-size: 14px">收货方省市区信息</span>
          </div>
          <div>
            <el-row>
              <el-col :span="24">
                <BuyerListCityLinkage @BuyerList="getBuyerListCityLinkageCity" :BuyerList="BuyerList"></BuyerListCityLinkage>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-form>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="plan_savewaybill">保 存</el-button>
        <el-button @click="handleClose_planwaybill">关闭</el-button>
      </span>
    </el-dialog>
    <el-dialog title="收货方查询" :visible.sync="dialogTableVisible1" width="85%" @close="closedialogTableVisible1">
      <el-form :inline="true" v-model="form1" size="mini" label-position="center">
        <el-row>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方编码" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.consumerId" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方名称" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.consumerName" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方地址" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.consumerAddress" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="收货方联系人" label-width="100px" style="width: 260px">
                <el-input size="mini" v-model="form1.consumerLinkman" style="width: 140px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-form-item label="线路名称" label-width="90px" style="width: 250px">
                <el-input size="mini" v-model="form1.lineName" style="width: 150px"></el-input>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="grid-content">
              <el-button type="primary" plain size="mini" @click="vendeeSearch">查询</el-button>
              <el-button type="primary" plain size="mini" @click="veeOkay">确定</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <el-row class="waybill_add_dialog">
        <flight-table
          :tableData="veetableData"
          :columns="veeColumns"
          :pagesizes="pagesizes"
          :isPagination="true"
          @handleSelectionChange="handleSelectionChange1"
          @clickTable="dubbleClick1"
          @handleCurrentChange="handleCurrentChange_compan1"
          @handleSizeChange="handleSizeChange1"
          :total="pagination1.total"
          :pageSize="pagination1.pageSize"
          :current-page="pagination1.pageNum"
        ></flight-table>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
//引入组件
import cesTable from '../../components/common/table.vue'
import Drawer from '../../components/common/drawer.vue'
import axios from 'axios'
import arrayQuery from '../../components/arrayQuery.js'
import qs from 'qs'
import $ from 'jquery'
import 'element-ui/lib/theme-chalk/index.css'
import BuyerListCityLinkage from '../../components/common/BuyerAddressInformation.vue'
import SellerListCityLinkage from '../../components/common/SellerAddressInformation.vue'
import fileDownload from 'js-file-download'
import flightTable from '../../components/common/flightTable.vue'
export default {
  data() {
    return {
      table: false,
      dialog: false,
      loading: false,
      dialog_waybillMan_JustShow_tit: '',
      dialogVisible_JustShow: false,
      dialogVisible_planwaybill: false,
      dialogTableVisible1: false,
      materialTableData_JustShow: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              picker.$emit('pick', new Date())
            },
          },
          {
            text: '昨天',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', date)
            },
          },
          {
            text: '一周前',
            onClick(picker) {
              const date = new Date()
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', date)
            },
          },
        ],
      },
      AccurateAndUnique_preOrderNo: '',
      dataForms: {
        preOrderNo: '',
        orderNo: '',
        vendeeName: '',
        vendeeLinkman: '',
        consignor: '',
        status: '',
        createTimeBegin: '',
        createTimeEnd: '',
      },
      formLabelWidth: '80px',
      timer: null,
      apiId: 'null',
      fieldList: [],
      form1: {
        consumerId: '',
        consumerName: '',
        consumerAddress: '',
        consumerLinkman: '',
        lineName: '',
      },
      pagination1: {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      },

      veetableData: [],
      veeColumns: [
        {
          id: 'selection',
          type: 'selection',
          label: '',
          fixed: 'left',
          width: '55',
          prop: '',
          isShow: true,
          align: 'center',
        },
        {
          id: 'lineName',
          type: '',
          label: '线路名称',
          fixed: false,
          width: '140',
          prop: 'lineName',
          isShow: true,
          align: 'center',
        },
        {
          id: 'lineType',
          type: '',
          label: '线路类型',
          fixed: false,
          width: '100',
          prop: 'lineType',
          isShow: true,
          align: 'center',
          formatter: row => {
            if (row.lineType == 0) {
              return '市内'
            } else if (row.lineType == 1) {
              return '外阜'
            } else {
              return ''
            }
          },
        },
        {
          id: 'timelinessType',
          type: '',
          label: '线路时效类型',
          fixed: false,
          width: '120',
          prop: 'timelinessType',
          isShow: true,
          align: 'center',
          formatter: row => {
            if (row.timelinessType == 1) {
              return '固定值'
            } else if (row.timelinessType == 2) {
              return '小时'
            } else if (row.timelinessType == 3) {
              return '某天某时刻'
            }
          },
        },
        {
          id: 'timeliness',
          type: '',
          label: '线路时效值',
          fixed: false,
          width: '100',
          prop: 'timeliness',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerId',
          type: '',
          label: '收货方编码',
          fixed: false,
          width: '100',
          prop: 'consumerId',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerName',
          type: '',
          label: '收货方名称',
          fixed: false,
          width: '180',
          prop: 'consumerName',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerAddress',
          type: '',
          label: '收货方地址',
          fixed: false,
          width: '180',
          prop: 'consumerAddress',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerLinkman',
          type: '',
          label: '收货方联系人',
          fixed: false,
          width: '110',
          prop: 'consumerLinkman',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerMobile',
          type: '',
          label: '收货方手机号',
          fixed: false,
          width: '150',
          prop: 'consumerMobile',
          isShow: true,
          align: 'center',
        },
        {
          id: 'consumerTelephone',
          type: '',
          label: '收货方固话',
          fixed: false,
          width: '180',
          prop: 'consumerTelephone',
          isShow: true,
          align: 'center',
        },
        {
          id: 'provinceCode',
          type: '',
          label: '省',
          fixed: false,
          width: '120',
          prop: 'provinceCode',
          isShow: true,
          align: 'center',
        },
        {
          id: 'cityCode',
          type: '',
          label: '市',
          fixed: false,
          width: '120',
          prop: 'cityCode',
          isShow: true,
          align: 'center',
        },
        {
          id: 'districtCode',
          type: '',
          label: '区',
          fixed: false,
          width: '120',
          prop: 'districtCode',
          isShow: true,
          align: 'center',
        },
      ],

      pagesizes: [5, 10, 15, 20],
      // 表格
      tableData: [], //表格数据
      tableCols: [
        //表格列数据
        //{label:'外部单据号',prop:'preOrderNo',width:'120',align:'center'},
        {
          label: '创建时间',
          prop: 'createTime',
          width: '150',
          align: 'center',
          sortable: true,
          formatter: (row, column) => {
            if (row.createTime == null) {
              return ''
            } else {
              return this.$moment(row.createTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        { label: '外部单据号', type: 'imgLinkProNoder', prop: 'preOrderNo', align: 'center', width: '120' },

        {
          label: '状态',
          prop: 'status',
          align: 'center',
          formatter: row => {
            if (row.status == 0) {
              return '初始'
            } else if (row.status == 2) {
              return '已确认'
            } else if (row.status == 4) {
              return '已撤单'
            } else {
              return ''
            }
          },
        },
        { label: '发货方', prop: 'consignor', width: '160', align: 'center' },
        { label: '收货方', prop: 'vendeeName', align: 'center', width: '320', sortable: true },
        {
          label: '收货方省市区信息',
          prop: 'vendeeProvinceName',
          align: 'center',
          width: '160',
          formatter: row => {
            let vendeeProvinceName = ''
            let vendeeCityName = ''
            let vendeeDistrictName = ''
            if (row.vendeeProvinceName !== null) {
              vendeeProvinceName = row.vendeeProvinceName
            } else {
              vendeeProvinceName = ''
            }
            if (row.vendeeCityName !== null) {
              vendeeCityName = row.vendeeCityName
            } else {
              vendeeCityName = ''
            }
            if (row.vendeeDistrictName !== null) {
              vendeeDistrictName = row.vendeeDistrictName
            } else {
              vendeeDistrictName = ''
            }
            let aa = vendeeProvinceName + vendeeCityName + vendeeDistrictName
            return aa
          },
        },
        {
          label: '发货方省市区信息',
          prop: 'consignProvinceName',
          align: 'center',
          width: '160',
          formatter: row => {
            let consignProvinceName = ''
            let consignCityName = ''
            let consignDistrictName = ''
            if (row.consignProvinceName !== null) {
              consignProvinceName = row.consignProvinceName
            } else {
              consignProvinceName = ''
            }
            if (row.consignCityName !== null) {
              consignCityName = row.consignCityName
            } else {
              consignCityName = ''
            }
            if (row.consignDistrictName !== null) {
              consignDistrictName = row.consignDistrictName
            } else {
              consignDistrictName = ''
            }
            let aa = consignProvinceName + consignCityName + consignDistrictName
            return aa
          },
        },
        { label: '收货方联系人', prop: 'vendeeLinkman', align: 'center', width: '120' },
        { label: '发货方联系人', prop: 'consignLinkman', align: 'center', width: '120' },
        { label: '发货方手机', prop: 'consignMobile', align: 'center', width: '120' },
        { label: '收货方联系方式', prop: 'vendeeMobile', align: 'center', width: '120' },
        { label: '收货方地址', prop: 'vendeeAddress', align: 'center', width: '400' },
        { label: '件数', prop: 'pkgQty', align: 'center', width: '120' },
        { label: '重量（kg）', prop: 'weight', align: 'center', width: '120' },
        {
          label: '体积（m³）',
          prop: 'volume',
          align: 'center',
          width: '120',
          formatter: (row, column) => {
            return this.getFloat(row.volume, 4)
          },
        },
        {
          label: '客户要求到货时间',
          prop: 'requiredDeliveryTime',
          width: '150',
          align: 'center',
          formatter: (row, column) => {
            if (row.requiredDeliveryTime == null) {
              return ''
            } else {
              return this.$moment(row.requiredDeliveryTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        { label: '单据来源', prop: 'orderSource', align: 'center' },

        {
          label: '最近一次修改时间',
          prop: 'modifyTime',
          width: '150',
          align: 'center',
          formatter: (row, column) => {
            if (row.modifyTime == null) {
              return ''
            } else {
              return this.$moment(row.modifyTime).format('YYYY-MM-DD HH:mm:ss')
            }
          },
        },
        { label: '备注', prop: 'remark', align: 'center' },
        { label: '运单编号', prop: 'orderNo', width: '160', align: 'center' },
      ],
      pagination: {
        //分页数据
        pageSize: 50,
        pageNum: 1,
        total: 0,
      },
      merge: [],
      searchOne: true,
      searchFlag: true,
      mergeFlag: true,
      planModifyFlag: true,
      cancelFlag: true,
      exportFlag: true,
      waybillDoForm: {
        id: '',
        vendeeProvince: '',
        vendeeProvinceName: '',
        vendeeCity: '',
        vendeeCityName: '',
        vendeeDistrict: '',
        vendeeDistrictName: '',
        consignProvince: '',
        consignProvinceName: '',
        consignCity: '',
        consignCityName: '',
        consignDistrict: '',
        consignDistrictName: '',
      },
      BuyerList: {
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: '',
      },
      SellerList: {
        province: '',
        provinceName: '',
        city: '',
        cityName: '',
        area: '',
        areaName: '',
      },
      downloadName: '',
      consumerInfoDto: {}, // 那就是这个了
      preOrderNos: [],
      veeData: {},
    }
  },
  components: {
    //引入组件后注册组件
    cesTable,
    Drawer,
    flightTable,
    BuyerListCityLinkage,
    SellerListCityLinkage,
  },
  mounted() {
    this.getTableAndForm()
    let parentId = '2_2_1'
    let currentPageButtons = arrayQuery.text(parentId)
    let _this = this
  },
  methods: {
    veeOkay() {
      console.log(2222, this.veeData)
      if (this.veeData.length == 0) {
        this.$message({
          message: '请先勾选一条收货方信息',
          type: 'warning',
        })
      } else if (this.veeData.length > 1) {
        this.$message({
          message: '仅允许勾选一条收货方信息',
          type: 'warning',
        })
      } else {
        //吧下面所有的val换成this.veeData[0],你操作下这个?对,都换一下
        console.log(11112, this.veeData[0])
        this.consumerInfoDto.consumerName = this.veeData[0].consumerName
        this.consumerInfoDto.provinceCode = this.veeData[0].provinceCode
        this.consumerInfoDto.cityCode = this.veeData[0].cityCode
        this.consumerInfoDto.districtCode = this.veeData[0].districtCode
        this.consumerInfoDto.consumerAddress = this.veeData[0].consumerAddress
        this.consumerInfoDto.consumerLinkman = this.veeData[0].consumerLinkman
        this.consumerInfoDto.consumerMobile = this.veeData[0].consumerMobile
        this.consumerInfoDto.lineName = this.veeData[0].lineName
        this.consumerInfoDto.lineType = this.veeData[0].lineType
        this.consumerInfoDto.timelinessType = this.veeData[0].timelinessType
        this.consumerInfoDto.timeliness = this.veeData[0].timeliness
        this.consumerInfoDto.remark = this.veeData[0].remark
        console.log(this.consumerInfoDto, this.preOrderNos)
        this.combineManualConfirmNew()
        //   this.$refs.consumerInfoDto.clearValidate()
        this.dialogTableVisible1 = false
      }
    },
    vendeeSearch() {
      let params = {
        consumerId: this.form1.consumerId,
        consumerName: this.form1.consumerName,
        consumerAddress: this.form1.consumerAddress,
        consumerLinkman: this.form1.consumerLinkman,
        lineName: this.form1.lineName,
        pageSize: this.pagination1.pageSize,
        pageNo: this.pagination1.pageNum,
      }
      $.ajax({
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization',
          Authorization: this.$store.state.token,
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true,
        },
        async: true,
        url: `${this.$apiPath}/api/urban/consumer/queryConsumerInfosUtilPage`,
        data: JSON.stringify(params),
        beforeSend: request => {
          request.setRequestHeader('Authorization', this.$store.state.token)
          // request.setRequestHeader("token", this.$store.state.token);
        },
        success: res => {
          if (res.success == true) {
            this.veetableData = res.data.items
            console.log(this.veetableData)

            this.pagination1.total = res.data.total
          } else {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        },
      })
    },
    closedialogTableVisible1() {
      this.form1 = {}
      this.pagination1 = {
        pageSize: 5,
        pageNum: 1,
        total: 0,
      }
    },
    dubbleClick1(val) {
      console.log(11112, val)
      this.consumerInfoDto.consumerName = val.row.consumerName
      this.consumerInfoDto.provinceCode = val.row.provinceCode
      this.consumerInfoDto.cityCode = val.row.cityCode
      this.consumerInfoDto.districtCode = val.row.districtCode
      this.consumerInfoDto.consumerAddress = val.row.consumerAddress
      this.consumerInfoDto.consumerLinkman = val.row.consumerLinkman
      this.consumerInfoDto.consumerMobile = val.row.consumerMobile
      this.consumerInfoDto.lineName = val.row.lineName
      this.consumerInfoDto.lineType = val.row.lineType
      this.consumerInfoDto.timelinessType = val.row.timelinessType
      this.consumerInfoDto.timeliness = val.row.timeliness
      this.consumerInfoDto.remark = val.row.remark
      console.log(this.consumerInfoDto, this.preOrderNos)
      this.combineManualConfirmNew()

      this.dialogTableVisible1 = false
    },
    handleCurrentChange_compan1(val) {
      this.pagination1.pageNum = val
      this.vendeeSearch()
    },
    handleSelectionChange1(val) {
      console.log(12312312, val)
      this.veeData = val.val
    },
    handleSizeChange1(val) {
      this.pagination1.pageSize = val
      this.vendeeSearch()
    },

    getBuyerListCityLinkageCity(data) {
      this.BuyerList.province = data.province
      this.BuyerList.provinceName = data.provinceName
      this.BuyerList.city = data.city
      this.BuyerList.cityName = data.cityName
      this.BuyerList.area = data.area
      this.BuyerList.areaName = data.areaName
      this.waybillDoForm.vendeeProvince = data.province
      this.waybillDoForm.vendeeProvinceName = data.provinceName
      this.waybillDoForm.vendeeCity = data.city
      this.waybillDoForm.vendeeCityName = data.cityName
      this.waybillDoForm.vendeeDistrict = data.area
      this.waybillDoForm.vendeeDistrictName = data.areaName
    },
    getSellerListCityLinkageCity(data) {
      this.SellerList.province = data.province
      this.SellerList.provinceName = data.provinceName
      this.SellerList.city = data.city
      this.SellerList.cityName = data.cityName
      this.SellerList.area = data.area
      this.SellerList.areaName = data.areaName
      this.waybillDoForm.consignProvince = data.province
      this.waybillDoForm.consignProvinceName = data.provinceName
      this.waybillDoForm.consignCity = data.city
      this.waybillDoForm.consignCityName = data.cityName
      this.waybillDoForm.consignDistrict = data.area
      this.waybillDoForm.consignDistrictName = data.areaName
    },
    handleClose_planwaybill() {
      this.dialogVisible_planwaybill = false
      this.clearBuyerListData()
      this.clearSellerListData()
    },
    clearBuyerListData() {
      this.BuyerList.province = ''
      this.BuyerList.provinceName = ''
      this.BuyerList.city = ''
      this.BuyerList.cityName = ''
      this.BuyerList.area = ''
      this.BuyerList.areaName = ''
    },
    clearSellerListData() {
      this.SellerList.province = ''
      this.SellerList.provinceName = ''
      this.SellerList.city = ''
      this.SellerList.cityName = ''
      this.SellerList.area = ''
      this.SellerList.areaName = ''
    },
    getFloat(number, n) {
      n = n ? parseInt(n) : 0
      if (n <= 0) {
        return Math.round(number)
      }
      number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n) //四舍五入
      number = Number(number).toFixed(n) //补足位数
      return number
    },
    handleProNoderClick(preOrderNo) {
      const orderNo = new URLSearchParams()
      orderNo.append('preOrderNo', preOrderNo.imgurl)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/plan/waybill/getPlanWaybillItem`,
        data: orderNo,
      }).then(res => {
        this.materialTableData_JustShow = res.data.data
        this.dialog_waybillMan_JustShow_tit = '外部单据号' + preOrderNo.imgurl + '物料明细'
        this.dialogVisible_JustShow = true
      })
    },
    handleClose_JustShow() {
      this.dialogVisible_JustShow = false
      this.materialTableData_JustShow = []
    },
    //此方法是点击分页时触发的查询，
    handleCurrentChange() {
      this.planwaybillSearch()
    },
    //获取表格和表单数据
    async getTableAndForm() {
      this.planwaybillSearch()
    },
    handleQuery() {
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      //快捷查询
      const data = new URLSearchParams()
      data.append('preOrderNo', this.AccurateAndUnique_preOrderNo)
      data.append('pageSize', this.pagination.pageSize)
      data.append('pageNo', this.pagination.pageNum)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/plan/waybill/query`,
        data: data,
      }).then(res => {
        this.tableData = res.data.items
        this.pagination.total = res.data.total
        this.loading = false
      })
    },
    //查询
    planwaybillSearch() {
      this.loading = true
      let createTimeBegin
      let createTimeEnd
      if (this.dataForms.createTimeBegin == '' || this.dataForms.createTimeBegin == null) {
        createTimeBegin = ''
      } else {
        createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format('YYYY-MM-DD')
      }
      if (this.dataForms.createTimeEnd == '' || this.dataForms.createTimeEnd == null) {
        createTimeEnd = ''
      } else {
        createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format('YYYY-MM-DD')
      }
      const data = new URLSearchParams()
      data.append('vendeeName', this.dataForms.vendeeName)
      data.append('vendeeLinkman', this.dataForms.vendeeLinkman)
      data.append('consignor', this.dataForms.consignor)
      data.append('preOrderNo', this.dataForms.preOrderNo)
      data.append('orderNo', this.dataForms.orderNo)
      data.append('status', this.dataForms.status)
      data.append('createTimeBegin', createTimeBegin)
      data.append('createTimeEnd', createTimeEnd)
      data.append('pageSize', this.pagination.pageSize)
      data.append('pageNo', this.pagination.pageNum)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/plan/waybill/query`,
        data: data,
      }).then(res => {
        this.tableData = res.data.items
        this.pagination.total = res.data.total
        this.loading = false
      })
    },
    planwaybillSearch_Multiple() {
      this.pagination.pageSize = 50
      this.pagination.pageNum = 1
      this.pagination.total = 0
      this.planwaybillSearch()
    },
    // 获取表格勾选数据
    selectionLineChangeHandle(val) {
      this.merge = this.$refs.cesTable.multipleSelection
    },
    //计划运单修改
    planwaybillModify() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '修改操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '修改操作只能选择单条数据进行操作',
        })
      } else {
        this.dialogVisible_planwaybill = true
        const preOrderNo = new URLSearchParams()
        preOrderNo.append('preOrderNo', this.merge[0].preOrderNo)
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/plan/waybill/getPlanWaybill`,
          data: preOrderNo,
        }).then(res => {
          let processedData = this.nullToStr(res.data.data)
          this.BuyerList.province = processedData.vendeeProvince
          this.BuyerList.city = processedData.vendeeCity
          this.BuyerList.area = processedData.vendeeDistrict
          this.BuyerList.provinceName = processedData.vendeeProvinceName
          this.BuyerList.cityName = processedData.vendeeCityName
          this.BuyerList.areaName = processedData.vendeeDistrictName
          this.SellerList.province = processedData.consignProvince
          this.SellerList.city = processedData.consignCity
          this.SellerList.area = processedData.consignDistrict
          this.SellerList.provinceName = processedData.consignProvinceName
          this.SellerList.cityName = processedData.consignCityName
          this.SellerList.areaName = processedData.consignDistrictName
          this.waybillDoForm = processedData
        })
      }
    },
    //此方法是点击分页时触发的查询，
    handleCurrentChange() {
      this.planwaybillSearch()
    },
    handleProNoderClick(orderNo) {
      const preOrderNo = new URLSearchParams()
      this.dialogVisible_planwaybill = true
      preOrderNo.append('preOrderNo', this.merge[0].preOrderNo)
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/plan/waybill/getPlanWaybill`,
        data: preOrderNo,
      }).then(res => {
        let processedData = this.nullToStr(res.data.data)
        this.BuyerList.province = processedData.vendeeProvince
        this.BuyerList.city = processedData.vendeeCity
        this.BuyerList.area = processedData.vendeeDistrict
        this.BuyerList.provinceName = processedData.vendeeProvinceName
        this.BuyerList.cityName = processedData.vendeeCityName
        this.BuyerList.areaName = processedData.vendeeDistrictName
        this.SellerList.province = processedData.consignProvince
        this.SellerList.city = processedData.consignCity
        this.SellerList.area = processedData.consignDistrict
        this.SellerList.provinceName = processedData.consignProvinceName
        this.SellerList.cityName = processedData.consignCityName
        this.SellerList.areaName = processedData.consignDistrictName
        this.waybillDoForm = processedData
      })
    },

    //保存计划运单修改时的逻辑
    plan_savewaybill() {
      let planWaybillResultDto = {}
      planWaybillResultDto.id = this.waybillDoForm.id
      planWaybillResultDto.vendeeProvince = this.waybillDoForm.vendeeProvince
      planWaybillResultDto.vendeeCity = this.waybillDoForm.vendeeCity
      planWaybillResultDto.vendeeDistrict = this.waybillDoForm.vendeeDistrict
      planWaybillResultDto.consignProvince = this.waybillDoForm.consignProvince
      planWaybillResultDto.consignCity = this.waybillDoForm.consignCity
      planWaybillResultDto.consignDistrict = this.waybillDoForm.consignDistrict
      planWaybillResultDto.vendeeProvinceName = this.waybillDoForm.vendeeProvinceName
      planWaybillResultDto.vendeeCityName = this.waybillDoForm.vendeeCityName
      planWaybillResultDto.vendeeDistrictName = this.waybillDoForm.vendeeDistrictName
      planWaybillResultDto.consignProvinceName = this.waybillDoForm.consignProvinceName
      planWaybillResultDto.consignCityName = this.waybillDoForm.consignCityName
      planWaybillResultDto.consignDistrictName = this.waybillDoForm.consignDistrictName
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/plan/waybill/updatePlanWayBill`,
        data: planWaybillResultDto,
      }).then(res => {
        if (res.data.success == true) {
          this.$message({
            message: '修改成功',
            type: 'success',
          })
          this.handleClose_planwaybill()
          this.planwaybillSearch()
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
        }
      })
    },
    nullToStr(data) {
      for (var x in data) {
        if (data[x] === null) {
          // 如果是null 把直接内容转为 ''
          data[x] = ''
        } else {
          if (Array.isArray(data[x])) {
            // 是数组遍历数组 递归继续处理
            data[x] = data[x].map(z => {
              return nullToStr(z)
            })
          }
          if (typeof data[x] === 'object') {
            // 是json 递归继续处理
            data[x] = nullToStr(data[x])
          }
        }
      }
      return data
    },
    //合单
    planwaybillmerge() {
      this.selectionLineChangeHandle()
      console.log(1)
      var preOrderNos = []
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '合单操作必须选中一条数据',
        })
      } else {
        for (var i = 0; i < this.merge.length; i++) {
          if (this.merge[i].status == '4' || this.merge[i].status == '2') {
            this.$message({
              message: '已撤单或已确认的单子不允许合单，请重新勾选',
              type: 'success',
            })
            return false
          } else {
            preOrderNos.push(this.merge[i].preOrderNo)
          }
        }
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/plan/waybill/combine`,
          data: preOrderNos,
        }).then(res => {
          if (res.data.success == true) {
            this.$message({
              message: '合单成功',
              type: 'success',
            })
            this.planwaybillSearch()
          } else {
            this.$alert(res.data.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
        })
      }
    },
    //撤单
    planwaybillcancel() {
      this.selectionLineChangeHandle()
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '撤单操作必须选中一条数据',
        })
      } else if (this.merge.length > 1) {
        this.$message({
          type: 'info',
          message: '撤单操作只能选择单条数据进行操作',
        })
      } else {
        this.$confirm('此操作将对单据进行撤单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            axios({
              headers: {
                'Content-Type': 'application/json',
              },
              method: 'post',
              url: `${this.$apiPath}/api/urban/plan/waybill/recall`,
              data: this.merge[0].id,
            }).then(res => {
              if (res.data.success == true) {
                this.$message({
                  message: '撤单成功',
                  type: 'success',
                })
                this.planwaybillSearch()
              } else {
                this.$alert(res.data.msg, '提示', {
                  confirmButtonText: '确定',
                })
              }
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消撤单',
            })
          })
      }
    },
    plannedWaybillExport() {
      let createTimeBegin
      let createTimeEnd
      if (this.dataForms.createTimeBegin == '' || this.dataForms.createTimeBegin == null) {
        createTimeBegin = ''
      } else {
        createTimeBegin = this.$moment(this.dataForms.createTimeBegin).format('YYYY-MM-DD')
      }
      if (this.dataForms.createTimeEnd == '' || this.dataForms.createTimeEnd == null) {
        createTimeEnd = ''
      } else {
        createTimeEnd = this.$moment(this.dataForms.createTimeEnd).format('YYYY-MM-DD')
      }
      const data = new URLSearchParams()
      data.append('vendeeName', this.dataForms.vendeeName)
      // data.append('vendeeLinkman', this.dataForms.vendeeLinkman);
      data.append('consignor', this.dataForms.consignor)
      data.append('preOrderNo', this.dataForms.preOrderNo)
      data.append('orderNo', this.dataForms.orderNo)
      data.append('status', this.dataForms.status)
      data.append('createTimeBegin', createTimeBegin)
      data.append('createTimeEnd', createTimeEnd)
      data.append('pageSize', this.pagination.pageSize)
      data.append('pageNo', this.pagination.pageNum)
      var loading = this.$loading({
        lock: true,
        text: '导出中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      axios({
        method: 'post',
        url: `${this.$apiPath}/api/urban/plan/waybill/checkPlanExportCount`,
        data: data,
      }).then(res => {
        if (res.data.success == true) {
          axios({
            method: 'post',
            url: `${this.$apiPath}/api/urban/plan/waybill/exportPlan`,
            data: data,
            responseType: 'arraybuffer',
          })
            .then(res => {
              let url = window.URL.createObjectURL(new Blob([res.data], { type: '.xlsx' }))
              console.log(res.data)
              let a = document.createElement('a')
              a.style.display = 'none'
              a.href = url
              a.setAttribute('download', `计划运单报表.xlsx`)
              document.body.appendChild(a)
              a.click()
              url = window.URL.revokeObjectURL(url)
              document.body.removeChild(a)
              loading.close()
            })
            .catch(error => {
              this.$message.error('导出失败')
              loading.close()
            })
        } else {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
          })
          loading.close()
        }
      })
    },
    //人工合单
    combineManualCheck() {
      this.selectionLineChangeHandle()
      var preOrderNos = []
      let consumerInfoDto = {}
      if (this.merge.length == 0) {
        this.$message({
          type: 'info',
          message: '合单操作必须选中一条数据',
        })
      } else {
        for (var i = 0; i < this.merge.length; i++) {
          if (this.merge[i].status == '4' || this.merge[i].status == '2') {
            this.$message({
              message: '已撤单或已确认的单子不允许合单，请重新勾选',
              type: 'success',
            })
            return false
          } else {
            preOrderNos.push(this.merge[i].preOrderNo)
            this.preOrderNos = preOrderNos
            // 收货方信息对象
            consumerInfoDto = this.merge[i]
            console.log('1111', preOrderNos, consumerInfoDto)
          }
        }
        axios({
          method: 'post',
          url: `${this.$apiPath}/api/urban/plan/waybill/combineManualCheck`,
          data: preOrderNos,
        }).then(res => {
          console.log(res)
          if (res.data.success == true) {
            this.$message({
              message: '合单成功',
              type: 'success',
            })
            this.planwaybillSearch()
          } else {
            this.$confirm(res.data.msg, '提示', {
              // 警告!收货方信息不一致,是否确认合单?ok
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            })
              .then(() => {
                this.$confirm('<div>是否默认第一条收货方信息为合单收货人信息？</div><div>如果不是请点击 否 可以手动选择收货方信息！</div>', '提示', {
                  confirmButtonText: '是',
                  cancelButtonText: '否',
                  // showClose: false,
                  dangerouslyUseHTMLString: true,
                  distinguishCancelAndClose: true,
                })
                  .then(() => {
                    console.log('我选了是')
                    this.combineManualConfirmNew()
                  })
                  .catch(err => {
                    // 这里写选否的交互
                    console.log('我选了否', err)
                    if (err == 'cancel') {
                      this.dialogTableVisible1 = true
                      this.vendeeSearch()
                    }
                    if (err == 'close') {
                      this.dialogTableVisible1 = false
                      this.vendeeSearch()
                    }
                  })
                //   this.combineManualConfirmNew()
                //   $.ajax({
                //     type: 'post',
                //     dataType: 'json',
                //     contentType: 'application/json',
                //     headers: {
                //       'Access-Control-Allow-Origin': '*',
                //       'Access-Control-Allow-Headers': 'Authorization',
                //       Authorization: this.$store.state.token,
                //     },
                //     //设置允许ajax请求鞋带cookie即可
                //     xhrFields: {
                //       withCredentials: true,
                //     },
                //     async: true,
                //     url: `${this.$apiPath}/api/urban/plan/waybill/combineManualConfirmNew`,
                //     data: JSON.stringify({
                //       preOrderNos,
                //       consumerInfoDto,
                //     }),
                //     beforeSend: request => {
                //       request.setRequestHeader('Authorization', this.$store.state.token)
                //       // request.setRequestHeader("token", this.$store.state.token);
                //     },
                //     success: res => {
                //       if (res.success == true) {
                //         this.$alert(res.msg, '提示', {})
                //         this.planwaybillSearch()
                //       } else {
                //         this.$alert(res.msg, '提示', {
                //           confirmButtonText: '确定',
                //         })
                //       }
                //     },
                //     error: () => {
                //       this.$message({
                //         type: 'info',
                //         message: '已取消合单',
                //       })
                //     },
                //   })
              })
              .catch(() => {
                // 这里是点击取消的交互
              })
          }
        })
      }
    },
    combineManualConfirmNew() {
      $.ajax({
        type: 'post',
        dataType: 'json',
        contentType: 'application/json',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Authorization',
          Authorization: this.$store.state.token,
        },
        //设置允许ajax请求鞋带cookie即可
        xhrFields: {
          withCredentials: true,
        },
        async: true,
        url: `${this.$apiPath}/api/urban/plan/waybill/combineManualConfirmNew`,
        data: JSON.stringify({
          preOrderNos: this.preOrderNos,
          consumerInfoDto: this.consumerInfoDto,
        }),
        beforeSend: request => {
          request.setRequestHeader('Authorization', this.$store.state.token)
          // request.setRequestHeader("token", this.$store.state.token);
        },
        // 合并成功?
        success: res => {
          if (res.success == true) {
            this.$alert(res.msg, '提示', {})
            this.planwaybillSearch()
          } else {
            this.$alert(res.msg, '提示', {
              confirmButtonText: '确定',
            })
          }
          this.dialogTableVisible1 = false
        },
        // 合并失败?
        error: () => {
          this.$message({
            type: 'info',
            message: '已取消合单',
          })
          this.dialogTableVisible1 = false
        },
      })
    },
    // combineManualCheck(){
    //   this.selectionLineChangeHandle();
    //   var preOrderNos = [];
    //   if(this.merge.length == 0){
    //     this.$message({
    //       type: 'info',
    //       message: '合单操作必须选中一条数据'
    //     });
    //   }else{
    //     for(var i = 0;i<this.merge.length;i++){
    //       if(this.merge[i].status == '4' || this.merge[i].status == '2'){
    //         this.$message({
    //           message: '已撤单或已确认的单子不允许合单，请重新勾选',
    //           type: 'success'
    //         });
    //         return false;
    //       }else {
    //         preOrderNos.push(this.merge[i].preOrderNo)
    //       }
    //     }
    //     axios({
    //       method: 'post',
    //       url: `${this.$apiPath}/api/urban/plan/waybill/combineManualCheck`,
    //       data:preOrderNos
    //     }).then(res=> {
    //       if(res.data.success == true){
    //         this.$message({
    //           message: '合单成功',
    //           type: 'success'
    //         });
    //         this.planwaybillSearch();
    //       }else {
    //         this.$confirm(res.data.msg, '提示', {
    //           confirmButtonText: '确定',
    //           cancelButtonText: '取消',
    //         }).then(() => {
    //           this.$message({
    //             type: 'success',
    //             message: '合单成功!'
    //           });
    //         }).catch(() => {
    //           this.$message({
    //             type: 'info',
    //             message: '已取消合单'
    //           });
    //         });
    //       }
    //     });
    //   }
    // },
    handleSearch() {
      this.dialog = true
      let form = localStorage.getItem('plan')
      if (form) {
        let obj = JSON.parse(form)
        for (let key in obj) {
          this.$set(this.dataForms, key, obj[key])
        }
      }
    },
    handleClose(done) {
      if (this.loading) {
        return
      }
      this.loading = true
      this.timer = setTimeout(() => {
        // 动画关闭需要一定的时间
        setTimeout(() => {
          this.loading = false
        }, 400)
        this.planwaybillSearch_Multiple()
        localStorage.setItem('plan', JSON.stringify(this.dataForms))
        // 动画关闭需要一定的时间
        this.dialog = false
      }, 2000)
    },
    cancelForm() {
      this.loading = false
      this.dialog = false
      clearTimeout(this.timer)
    },
    //重置按钮
    resetFrom() {
      this.$refs['dataForms'].resetFields()
    },
  },
}
</script>
<style>
.tableMain .ces-table-page {
  height: 100%;
}
.tableMain .ces-table {
  height: 100%;
}
.tableMain .el-table th {
  padding: 0;
  height: 35px;
}
.tableMain .el-table td {
  padding: 0;
  height: 35px;
}
</style>
<style scoped>
.boxbutton {
  width: 100%;
  height: 40px;
}
.tableMain {
  width: 100%;
  height: 82%;
}
</style>
